import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import { escapeRegExp } from "lodash";
import { DialogOverlay, DialogContent } from "@reach/dialog";
import DarkModeToggle from "./DarkModeToggle";
import IconButton from "./IconButton";
import SearchBar from "./SearchBar";
import SearchResults from "./SearchResults";
import Sidebar from "./Sidebar";

function SearchDialog({ isOpen, onDismiss }) {
  const [value, setValue] = React.useState("");
  const [results, setResults] = React.useState([]);

  const {
    allMarkdownRemark: { nodes },
  } = useStaticQuery(graphql`
    query listPostForSearch {
      allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
        nodes {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  `);

  const posts = nodes.map(({ fields: { slug }, frontmatter: { title } }) => ({
    slug,
    title,
  }));

  const handleChange = ({ target: { value } }) => {
    setValue(value);
    if (value.length < 2) return setResults([]);

    const re = new RegExp(escapeRegExp(value), "i");
    setResults(posts.filter((post) => re.test(post.title)));
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Overlay isOpen={isOpen} onDismiss={onDismiss}>
      <Content>
        <Actions>
          <DarkModeToggle />
          <IconButton onClick={onDismiss} icon="close" title="dismiss menu" />
        </Actions>
        <SearchBar value={value} onChange={handleChange} />
        <SearchResults results={results} />
        <Tags onDismiss={onDismiss} />
      </Content>
    </Overlay>
  );
}

const Overlay = styled(DialogOverlay)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--color-background-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled(DialogContent)`
  background: var(--color-background-secondary);
  width: 100%;
  height: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: var(--spacing-8);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Tags = styled(Sidebar)``;

export default SearchDialog;
