import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const Seo = ({ description, lang, meta, title }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
            title
            description
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title || site.siteMetadata.title}
      titleTemplate={title && `%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `author`,
          content: site.siteMetadata.author,
        },
        {
          name: `keywords`,
          content:
            "코딩, 웹, 개발, 기초, 강의, 입문, HTML, CSS, JavaScript, 자바스크립트, 사용법, 가이드",
        },
        {
          property: `og:title`,
          content: title || site.siteMetadata.title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: `https://res.cloudinary.com/daleseo/image/upload/v1648323273/daleseo/og.png`,
        },
        {
          property: `naver-site-verification`,
          content: `edea6f7ef876eb3cdc3c7975b2df61b7e7368cf4`,
        },
      ].concat(meta)}
    />
  );
};

Seo.defaultProps = {
  lang: `ko`,
  meta: [],
  description: ``,
  title: ``,
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export default Seo;
