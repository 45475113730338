import React from "react";
import {
  ChevronLeft,
  ChevronRight,
  Clock,
  MessageCircle,
  Menu,
  Moon,
  Search,
  Sun,
  Tag,
  X,
} from "react-feather";
import {
  Discord,
  Github,
  Medium,
  Twitter,
  Youtube,
} from "@icons-pack/react-simple-icons";

const ICONS = {
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  clock: Clock,
  messageCircle: MessageCircle,
  menu: Menu,
  moon: Moon,
  search: Search,
  sun: Sun,
  tag: Tag,
  close: X,
  discord: Discord,
  github: Github,
  medium: Medium,
  youtube: Youtube,
  twitter: Twitter,
};

function Icon({ name, color, size, strokeWidth, ...rest }) {
  const Component = ICONS[name];

  if (!Component) {
    throw new Error(`No icon found from name: ${name}`);
  }

  return (
    <Component
      aria-hidden="true"
      focusable="false"
      size={size}
      color={color}
      {...rest}
    />
  );
}

export default Icon;
