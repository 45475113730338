import * as React from "react";
import styled from "styled-components";
import Logo from "./Logo";
import Social from "./Social";

function Footer() {
  return (
    <Wrapper>
      <Row>
        <SmallLogo />

        <section>
          <Social />
        </section>
      </Row>
      <section>
        <Copyright>
          © 2016-{new Date().getFullYear()} DaleSeo. All Rights Reserved.
        </Copyright>
      </section>
    </Wrapper>
  );
}

const Wrapper = styled.footer`
  padding: var(--spacing-8);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-4);
  justify-content: space-around;
  align-items: center;
`;

const SmallLogo = styled(Logo)`
  height: 28px;
`;

const Copyright = styled.p`
  text-align: center;
  font-size: var(--font-size-3);
  font-weight: var(--font-weight-regular);
  color: var(--color-gray);
`;

export default Footer;
