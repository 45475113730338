import React from "react";
import styled from "styled-components";
import useDarkMode from "use-dark-mode";
import IconButton from "./IconButton";

const DarkModeToggle = ({ ...rest }) => {
  const darkMode = useDarkMode(false);

  return (
    <Wrapper {...rest}>
      {darkMode.value ? (
        <IconButton
          icon="moon"
          title="Activate light mode"
          onClick={darkMode.disable}
        />
      ) : (
        <IconButton
          icon="sun"
          title="Activate dark mode"
          onClick={darkMode.enable}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

export default DarkModeToggle;
