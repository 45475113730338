import React from "react";
import styled from "styled-components";
import VisuallyHidden from "@reach/visually-hidden";
import UnstyledButton from "./UnstyledButton";
import Icon from "./Icon";

function IconButton({ onClick, icon, size, padding = 2, title, ...rest }) {
  return (
    <Wrapper onClick={onClick} padding={padding} {...rest}>
      <BlockIcon name={icon} size={size} aria-hidden="true" focusable="false" />
      <VisuallyHidden>{title || icon}</VisuallyHidden>
    </Wrapper>
  );
}

const Wrapper = styled(UnstyledButton)`
  display: block;
  padding: ${(p) => `var(--spacing-${p.padding})`};
`;

const BlockIcon = styled(Icon)`
  display: block;
`;

export default IconButton;
