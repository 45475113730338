import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";

import koreanTags from "../data/koreanTags.json";

const koreanRegex = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;

function Sidebar({ ...rest }) {
  const {
    allMarkdownRemark: { group: tags },
  } = useStaticQuery(graphql`
    query groupTags {
      allMarkdownRemark {
        group(field: fields___tags) {
          fieldValue
          totalCount
        }
      }
    }
  `);

  const topTags = tags
    .filter(({ fieldValue }) => !koreanRegex.test(fieldValue))
    .sort((a, b) => b.totalCount - a.totalCount)
    .slice(0, 75);

  return (
    <Wrapper {...rest}>
      <Nav>
        {topTags.map(({ fieldValue, totalCount }) => (
          <NavLink key={fieldValue} href={`/?tag=${fieldValue}`}>
            {koreanTags[fieldValue] || fieldValue}
          </NavLink>
        ))}
      </Nav>
    </Wrapper>
  );
}

const Wrapper = styled.aside``;

const Nav = styled.nav`
  display: flex;
  gap: var(--spacing-2);
  flex-wrap: wrap;
  justify-content: center;
`;

const NavLink = styled.a`
  padding: var(--spacing-1) var(--spacing-2);
  border-radius: var(--spacing-1);
  background: var(--color-background);
  color: var(--color-text);
  opacity: 0.7;
  font-size: var(--font-size-3);
  transition: opacity 500ms ease 0s;
  text-decoration: none;

  &:hover,
  &:focus {
    opacity: 1;
    transition: opacity 0ms ease 0s;
  }
`;

export default Sidebar;
