import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import GlobalStyle from "./GlobalStyle";
import Seo from "./Seo";
import Help from "./Help";
import Header from "./Header";
import Footer from "./Footer";
import MaxWidthWrapper from "./MaxWidthWrapper";

const Layout = ({ title, children, isDetail }) => {
  return (
    <>
      <Seo title={title} />
      <Wrapper>
        <HeaderWrapper>
          <MaxWidthWrapper>
            <Header />
          </MaxWidthWrapper>
        </HeaderWrapper>

        <MainWrapper isDetail={isDetail}>
          <MaxWidthWrapper>{children}</MaxWidthWrapper>
        </MainWrapper>

        <FooterWrapper>
          <MaxWidthWrapper>
            <Footer />
          </MaxWidthWrapper>
        </FooterWrapper>
      </Wrapper>
      <Help />
      <GlobalStyle />
    </>
  );
};

const Wrapper = styled.div`
  background: var(--color-background);
  isolation: isolate;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const HeaderWrapper = styled.div`
  position: sticky;
  z-index: 1;
  top: -16px;
  padding-top: 16px;
  transition: background 350ms ease 0s;
  background: var(--color-background);
  opacity: 0.85;
  backdrop-filter: blur(10px);
`;

const MainWrapper = styled.div`
  isolation: isolate;
  flex: 1;
  background: ${(p) =>
    p.isDetail
      ? "var(--color-background-secondary)"
      : "var(--color-background)"};
`;

const FooterWrapper = styled.div`
  isolation: isolate;
  transition: background 350ms ease 0s;
  background: var(--color-background-secondary);
`;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default Layout;
