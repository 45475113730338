import React from "react";
import styled from "styled-components";
import IconLink from "./IconLink";

const Wrapper = styled.div`
  position: fixed;
  bottom: var(--spacing-5);
  right: var(--spacing-5);
  background: var(--color-primary);
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

function Help() {
  return (
    <Wrapper>
      <IconLink
        external
        to="https://discord.gg/6TwzdnW6ze"
        icon="messageCircle"
        size={5 * 5}
      />
    </Wrapper>
  );
}

export default Help;
