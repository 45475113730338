import styled from "styled-components";

export default styled.div`
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 var(--spacing-4);
  @media (min-width: 34.375rem) {
    padding: 0 var(--spacing-8);
  }
`;
