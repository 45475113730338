import React from "react";
import styled from "styled-components";
import IconLink from "./IconLink";

const SOCIAL_ITEMS = {
  github: "https://github.com/DaleSeo",
  youtube: "https://youtube.com/@DaleSeo",
  twitter: "https://twitter.com/DaleSeo",
  discord: "https://discord.gg/6TwzdnW6ze",
  medium: "https://medium.com/@dale.seo",
};

function Social() {
  return (
    <Wrapper>
      {Object.entries(SOCIAL_ITEMS).map(([icon, to]) => (
        <li key={icon}>
          <IconLink external to={to} icon={icon} size={5 * 4} />
        </li>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.ul`
  display: flex;
  justify-content: center;
`;

export default Social;
