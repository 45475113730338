import React from "react";
import styled from "styled-components";
import Icon from "./Icon";

function SearchBar({ value, onChange }) {
  return (
    <Wrapper>
      <IconWrapper>
        <SearchIcon name="search" />
      </IconWrapper>
      <SearchInput
        type="text"
        placeholder="Search..."
        value={value}
        onChange={onChange}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  font-size: 1rem;
  min-width: 100px;

  display: flex;
  align-items: center;
`;

const SearchIcon = styled(Icon)``;

const IconWrapper = styled.div`
  position: absolute;
`;

const SearchInput = styled.input`
  width: 100%;
  border: none;
  border-bottom: 2px solid var(--color-text);
  background: transparent;
  color: var(--color-text);
  font-size: var(--font-size-6);
  padding: 0 0 0 1.5em;
  outline-offset: var(--spacing-1);
  line-height: 2em;
`;

export default SearchBar;
