import React from "react";
import styled from "styled-components";
import { DialogOverlay, DialogContent } from "@reach/dialog";
import IconButton from "./IconButton";
import DarkModeToggle from "./DarkModeToggle";

const MENU_ITEMS = [
  "GraphQL",
  "React",
  "HTML",
  "CSS",
  "JavaScript",
  "Python",
  "Java",
  "Docker",
];

const MobileNav = ({ isOpen, onDismiss }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Overlay isOpen={isOpen} onDismiss={onDismiss} aria-label="Menu">
      <Content>
        <Actions>
          <DarkModeToggle />
          <IconButton onClick={onDismiss} icon="close" title="dismiss menu" />
        </Actions>

        <Nav>
          {MENU_ITEMS.map((item) => (
            <NavLink key={item} href={`/?tag=${item}`}>
              {item}
            </NavLink>
          ))}
        </Nav>

        <Filler />
      </Content>
    </Overlay>
  );
};

const Overlay = styled(DialogOverlay)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--color-overlay);
  display: flex;
  justify-content: flex-end;
`;

const Content = styled(DialogContent)`
  background: var(--color-background);
  width: 248px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--spacing-8) var(--spacing-4) var(--spacing-8) var(--spacing-8);
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Nav = styled.nav`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
`;

const NavLink = styled.a`
  color: var(--color-gray);
  font-size: var(--font-size-5);
  font-weight: var(--font-weight-regular);
  text-decoration: none;
  text-transform: uppercase;
`;

const Filler = styled.div`
  height: 64px;
`;

export default MobileNav;
