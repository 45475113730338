import React from "react";
import styled from "styled-components";

function SearchResults({ results }) {
  return (
    <Wrapper>
      <List>
        {results.map(({ slug, title }) => (
          <Item key={slug}>
            <ItemLink href={slug}>{title}</ItemLink>
          </Item>
        ))}
      </List>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  overflow: auto;
`;

const List = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
`;

const Item = styled.li`
  background: var(--color-background);
  color: var(--color-text);
  padding: var(--spacing-2) var(--spacing-3);
  border-radius: var(--spacing-2);
`;

const ItemLink = styled.a`
  display: block;
  opacity: 0.7;
  transition: opacity 500ms ease 0s;
  text-decoration: none;

  &:hover,
  &:focus {
    opacity: 1;
    transition: opacity 0ms ease 0s;
  }
`;

export default SearchResults;
