import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import qs from "qs";
import Logo from "./Logo";
import IconButton from "./IconButton";
import DarkModeToggle from "./DarkModeToggle";
import Nav from "./Nav";
import SearchDialog from "./SearchDialog";
import MobileNav from "./MobileMav";

function PageHeader() {
  const [showSearch, setShowSearch] = React.useState(false);
  const [showMobileMenu, setShowMobileMenu] = React.useState(false);

  const search =
    typeof document === `undefined` ? "" : document.location.search;
  const { tag = null } = qs.parse(search, {
    ignoreQueryPrefix: true,
  });
  return (
    <>
      <Wrapper>
        <HeaderLogo />
        <HeaderMenu tag={tag} />
        <Actions>
          <DarkModeToggle />
          <SearchButton
            icon="search"
            title="open search"
            onClick={() => setShowSearch(true)}
          />
          <MobileButton
            icon="menu"
            title="open menu"
            onClick={() => setShowMobileMenu(true)}
          />
        </Actions>
      </Wrapper>
      <SearchDialog
        isOpen={showSearch}
        onDismiss={() => setShowSearch(false)}
      />
      <MobileNav
        isOpen={showMobileMenu}
        onDismiss={() => setShowMobileMenu(false)}
      />
    </>
  );
}

const Wrapper = styled.header`
  display: flex;
  gap: var(--spacing-4);
  align-items: center;
  padding: 16px 0;
`;

const HeaderLogo = styled(Logo)`
  margin-right: auto;
`;

const HeaderMenu = styled(Nav)`
  display: none;
  @media (min-width: 34.375rem) {
    display: revert;
  }
`;

const Actions = styled.div`
  display: flex;
`;

const SearchButton = styled(IconButton)`
  padding: var(--spacing-2);
  display: none;
  @media (min-width: 34.375rem) {
    display: block;
  }
`;

const MobileButton = styled(IconButton)`
  padding: var(--spacing-2);
  @media (min-width: 34.375rem) {
    display: none;
  }
`;

PageHeader.propTypes = {
  activeItem: PropTypes.string,
};

PageHeader.defaultProps = {
  activeItem: "",
};

export default PageHeader;
