import React from "react";
import styled from "styled-components";
import VisuallyHidden from "@reach/visually-hidden";
import { Link } from "gatsby";
import Icon from "./Icon";

function IconLink({
  to,
  title,
  icon,
  size,
  padding = 2,
  external = false,
  ...rest
}) {
  const Component = external ? ExternalLink : InternalLink;
  return (
    <Component to={to} padding={padding} {...rest}>
      <BlockIcon name={icon} size={size} />
      <VisuallyHidden>{title || icon}</VisuallyHidden>
    </Component>
  );
}

function ExternalLink({ children, to, padding, ...rest }) {
  return (
    <Wrapper
      target="_blank"
      rel="noopener noreferrer"
      href={to}
      padding={padding}
      {...rest}
    >
      {children}
    </Wrapper>
  );
}

function InternalLink({ children, to, padding, ...rest }) {
  return (
    <Wrapper as={Link} to={to} padding={padding} {...rest}>
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.a`
  display: block;
  padding: ${(p) => `var(--spacing-${p.padding})`};
`;

const BlockIcon = styled(Icon)`
  display: block;
`;

export default IconLink;
