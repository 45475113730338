import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

function Nav({ tag, ...rest }) {
  return (
    <Wrapper {...rest}>
      <List>
        <MinorItem>
          <NavLink to="/?tag=HTML" active={tag === "HTML"}>
            HTML
          </NavLink>
        </MinorItem>
        <MinorItem>
          <NavLink to="/?tag=CSS" active={tag === "CSS"}>
            CSS
          </NavLink>
        </MinorItem>
        <Item>
          <NavLink to="/?tag=JavaScript" active={tag === "JavaScript"}>
            JavaScript
          </NavLink>
        </Item>
        <MinorItem>
          <NavLink to="/?tag=React" active={tag === "React"}>
            React
          </NavLink>
        </MinorItem>
        <MinorItem>
          <NavLink to="/?tag=GraphQL" active={tag === "GraphQL"}>
            GraphQL
          </NavLink>
        </MinorItem>
        <Item>
          <NavLink to="/?tag=Python" active={tag === "Python"}>
            Python
          </NavLink>
        </Item>
        <Item>
          <NavLink to="/about">About</NavLink>
        </Item>
      </List>
    </Wrapper>
  );
}

const Wrapper = styled.nav``;

const List = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
`;

const Item = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MinorItem = styled(Item)`
  display: none;
  @media (min-width: 50rem) {
    display: revert;
  }
`;

const NavLink = styled(Link)`
  padding: var(--spacing-1) var(--spacing-2);
  text-decoration: none;
  color: var(--color-text);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-4);

  &:focus {
    outline-offset: 2px;
  }
`;

export default Nav;
